import React, { useContext, useEffect, useState, useRef } from "react";
import cx from "classnames";
import { graphql } from "gatsby";
import { kebabCase } from "lodash";

import {
  Accordion,
  Button,
  BuyNowPayLater,
  CTA,
  CustomCta,
  FlagBadgeWrapper,
  IconWrapper,
  ImageContainer,
  MemoizedVideoContainer,
  MiniProductCardsBlock,
  OutOfStockButton,
  ProductDropdown,
  PurchaseHighlights,
  ShareButton,
} from "../../components";
import { BNPLRenderType } from "../../components/BuyNowPayLater/BuyNowPayLater";
import { OkendoReviewsRating } from "../../components/OkendoReviews/OkendoReviews";
import OkendoAssets from "../../components/OkendoAssets/OkendoAssets";

import {
  buildShopifyVariantObj,
  formatMarkdown,
  formatPrice,
  formatProductAccordionItems,
  triggerChatBubble,
  triggerFireworkWidget,
} from "../../utils/helpers";

import MainLayout from "../../hoc/Layouts/MainLayout";

import CartContext from "../../contexts/Cart/Cart.context";
import MainContext from "../../contexts/Main/Main.context";

import Viewport from "../../hooks/Viewport";

import * as Analytics from "../../utils/analytics";
import Link from "../../hooks/Link";
import CarouselContainer from "./sub/CarouselContainer";
import SquareSelector from "../../components/SquareSelector/SquareSelector";
import appContent from "../../../configs/appContent";
import { shopifyVariant } from "../../shopify";
import { IconBullet } from "../../../static";
import {
  HALF_VIEW_WIDTH,
  ONE_THIRD_VIEW_WIDTH,
  QUARTER_VIEW_WIDTH,
} from "../../../configs/images";
import { Transition } from "@headlessui/react";

interface ConfiguratorPageProps {
  location: any;
  pageContext: any;
  data: {
    alamedaapi: {
      configuratorPage: ConfiguratorPageData;
      outOfStockNotificationRequest: any;
    };
  };
}

const ConfiguratorPage = ({
  data: {
    alamedaapi: { configuratorPage, outOfStockNotificationRequest },
  },
  pageContext,
  location,
}: ConfiguratorPageProps) => {
  const {
    media,
    flags,
    title,
    subtitle,
    description,
    okendoReview,
    sections,
    products,
    mediaText,
    modalMedia,
    mediaLink,
    mediaBlackOpacityPercent,
    mediaTranscriptUrl,
    productDropdownOptions,
    productsSectionTitle,
    productsSectionDescription,
    customizeDescription,
    customizeHighlights,
    customizeMedia,
    productsTabTitle,
    customizeTabTitle,
  } = configuratorPage;

  // CONTEXTS

  const {
    actions: { addItems, toggle },
  } = useContext(CartContext);

  const {
    state: {
      navbar: { height },
      fireworkData: { isFireworkAgentAvailable },
    },
  } = useContext(MainContext);

  // STATES
  const [selectedMedia, setSelectedMedia] = useState<UploadFile[]>(media);
  const [filteredProducts, setFilteredProducts] = useState<ProductData[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<ProductData>();
  const [selectedVariant, setSelectedVariant] = useState<ProductVariantData>();
  const [availableVariantOptions, setAvailableVariantOptions] = useState<
    Record<string, any>
  >({});
  const [selectedSeatingOption, setSelectedSeatingOption] = useState();
  const { width } = Viewport();
  const isMobile = width < 1024;
  const [productSubtotal, setProductSubtotal] = useState(0);
  const [loadingURLProduct, setLoadingURLProduct] = useState(true);
  const [shareUrl, setShareUrl] = useState("");

  // REFS
  const configuratorContainer = useRef<any>(null);

  // REDUCERS

  function filterReducer(selectedFilters, action) {
    switch (action.type) {
      default:
        return {
          ...selectedFilters,
          [action.type]: action.payload,
        };
    }
  }

  const [selectedFilters, dispatchFilter] = React.useReducer(filterReducer, {});

  // EFFECTS

  useEffect(() => {
    Analytics.trackPage("Configurator Session");

    // Get filters from URL params
    const params = new URLSearchParams(window.location.search);

    sections.map(section => {
      if (section.productFrames.length) {
        const selectedURLFrame = params.get("productFrame");
        selectedURLFrame &&
          dispatchFilter({
            type: "productFrame",
            payload: section.productFrames.find(
              frame => kebabCase(frame.text) === selectedURLFrame
            ),
          });
      }
      if (section.productFabrics.length) {
        const selectedURLFabric = params.get("productFabric");
        selectedURLFabric &&
          dispatchFilter({
            type: "productFabric",
            payload: section.productFabrics.find(
              fabric => kebabCase(fabric.text) === selectedURLFabric
            ),
          });
      }
    });
  }, []);

  // FILTER PRODUCT BASED ON FILTER SELECTIONS
  useEffect(() => {
    // When user selects the first filter, app will select the first option in the productFabrics
    if (selectedFilters.productFrame && !selectedFilters?.productFabric) {
      const variantsSection = sections.find(
        section => section.productFabrics.length
      );

      dispatchFilter({
        type: "productFabric",
        payload: variantsSection?.productFabrics.sort(
          (a, b) => a.priority - b.priority
        )[0],
      });

      scrollTo("#section-1");
      return;
    }

    // If more than 1 filter parameters exists, app should be able to filter
    if (Object.keys(selectedFilters).length > 1) {
      // If product is selected and user changes variant
      if (
        selectedProduct?.variants.some(
          v => v.productFrame.text === selectedFilters.productFrame.text
        ) &&
        selectedVariant?.title !== selectedFilters.productFabric.text
      ) {
        const variantToSelect = selectedProduct?.variants.find(
          variant => variant.title === selectedFilters.productFabric.text
        );

        return handleProductSelection({
          selectedProduct,
          selectedVariant: variantToSelect,
          selectedSeatingOption,
        });
      }

      // Filter product based on filters
      let filteredProductsArray: ProductData[] = products;

      Object.keys(selectedFilters).forEach(filter => {
        filteredProductsArray = filteredProductsArray.filter(product => {
          if (
            product.variants.some(v => {
              return v[filter]?.text === selectedFilters[filter].text;
            })
          ) {
            return true;
          }
          if (
            Array.isArray(product[filter]) &&
            product[filter]?.some(
              el => el.text === selectedFilters[filter].text
            )
          ) {
            return true;
          }

          if (product[filter]?.text === selectedFilters[filter].text) {
            return true;
          }
        });
      });

      setFilteredProducts(
        filteredProductsArray.sort((a, b) => a.seats - b.seats)
      );

      // Find sibling product if exists a product selected
      if (selectedProduct && selectedVariant) {
        const siblingProducts = products.filter(({ variants }) => {
          return variants.some(v =>
            selectedProduct.variants.some(
              v2 =>
                v2.configurationIcon?.icon.url === v.configurationIcon?.icon.url
            )
          );
        });

        const siblingproduct = siblingProducts.find(product =>
          product.variants.some(
            v => v.productFrame?.text === selectedFilters.productFrame.text
          )
        );

        if (!siblingproduct) {
          return handleProductSelection({
            selectedProduct: undefined,
            selectedVariant: undefined,
            selectedSeatingOption: undefined,
          });
        }

        const productToSelect = filteredProductsArray.find(
          product => product.title === siblingproduct?.title
        );
        const variantToSelect = productToSelect?.variants.find(
          variant => variant.title === selectedFilters.productFabric.text
        );
        if (productToSelect && variantToSelect) {
          handleProductSelection({
            selectedProduct: productToSelect,
            selectedVariant: variantToSelect,
            selectedSeatingOption,
          });
        }
      }

      const availableOptions = selectedFilters.productFrame.productMedia.map(
        ({ productFabric }) => productFabric.text
      );

      setAvailableVariantOptions({
        productFabric: availableOptions,
      });

      if (
        (selectedVariant?.title &&
          !availableOptions?.includes(selectedVariant?.title)) ||
        (selectedFilters.productFabric &&
          !availableOptions?.includes(selectedFilters.productFabric.text))
      ) {
        const firstOptionAvailable =
          selectedFilters.productFrame.productMedia.sort(
            (a, b) => a.priority - b.priority
          )[0];

        dispatchFilter({
          type: "productFabric",
          payload: firstOptionAvailable.productFabric,
        });
      }
    }

    return () => {};
  }, [selectedFilters]);

  // RESET PRODUCT SELECTION IF VARIANT IS DISABLED
  useEffect(() => {
    if (selectedVariant && !selectedVariant.enable) {
      handleProductSelection({
        selectedProduct: undefined,
        selectedVariant: undefined,
        selectedSeatingOption: undefined,
      });
    }
  }, [selectedVariant]);

  // CHANGE MAIN IMAGE BASED ON SELECTIONS

  const [galleryId, setGalleryId] = useState(
    `${selectedProduct?.slug}-${selectedVariant?.slug}`
  );
  useEffect(() => {
    if (selectedVariant) {
      const mediaGalleryItems = [
        ...selectedVariant.photos,
        ...selectedVariant
          .sharedGalleries!?.map(el => el.images)
          .flat()
          .map(el => ({ ...el, lifestyle: true })),
      ];
      setGalleryId(`${selectedProduct?.slug}-${selectedVariant?.slug}`);
      return setSelectedMedia(mediaGalleryItems);
    }

    const filtersLength = Object.keys(selectedFilters).length;
    if (
      filtersLength > 0 &&
      selectedFilters?.productFrame?.productMedia.length
    ) {
      if (filtersLength === 1) {
        const frameImage = [
          isMobile
            ? selectedFilters?.productFrame?.productMedia[0].mobileMedia
            : selectedFilters?.productFrame?.productMedia[0].media,
        ];
        setSelectedMedia(frameImage);
      }
      if (filtersLength === 2) {
        const variant = selectedFilters?.productFrame?.productMedia.filter(
          product =>
            product.productFabric.text === selectedFilters?.productFabric?.text
        );
        if (variant.length) {
          setSelectedMedia([
            isMobile ? variant[0].mobileMedia : variant[0].media,
          ]);
        }
      }
    }
  }, [selectedFilters, selectedVariant]);

  const handleProductSelection = ({
    selectedProduct,
    selectedVariant,
    selectedSeatingOption,
  }) => {
    setSelectedProduct(selectedProduct);
    setSelectedVariant(selectedVariant);
    setSelectedSeatingOption(selectedSeatingOption);
  };

  // SET PRODUCT ACCORDION
  const productAccordionMap = selectedProduct?.stackComponents?.length
    ? formatProductAccordionItems(selectedProduct?.stackComponents)
    : [];

  // ADD TO CART FUNCTION
  const addToCart = () => {
    if (selectedVariant) {
      const variantObj = buildShopifyVariantObj({
        product: selectedProduct,
        variant: selectedVariant,
        quantity: 1,
      });

      const variantsToCart = [...formattedCrossSellVariants, variantObj];

      toggle();
      addItems(variantsToCart);

      variantsToCart.forEach(variant => {
        const cartVariantObj = {
          b64VariantId: variant.id,
          b64ProductId: variant.product.id,
          variantTitle: variant.title,
          productTitle: variant.product.title,
          centsPrice: Number(variant.price),
          quantity: variant.quantity,
          path: window.location.pathname,
        };
        const cartTrackingObj = Analytics.genVariantTrackingObj(cartVariantObj);
        Analytics.track("Product Added", cartTrackingObj);
      });
    }
  };

  // CROSS SELL LOGIC

  const crossSellProducts = [
    ...(selectedProduct?.crossSellProducts ?? []),
    ...(selectedProduct?.sharedCrossSellProducts?.clusters ?? []),
  ];

  const [selectedCrossSellVariants, setSelectedCrossSellVariants] = useState<
    Record<string, ProductVariantData[]>
  >({});
  const [formattedCrossSellVariants, setFormattedCrossSellVariants] = useState<
    shopifyVariant[]
  >([]);

  useEffect(() => {
    const childrenVariants =
      selectedCrossSellVariants[selectedVariant?.slug!] || [];
    const formatedVariants = childrenVariants.map(variant =>
      buildShopifyVariantObj({
        product: {
          title: variant.productTitle,
          id: variant.productRemoteId,
        },
        variant,
        quantity: variant.quantity,
      })
    );
    setFormattedCrossSellVariants(formatedVariants);
  }, [selectedCrossSellVariants, selectedVariant]);

  // SET SUBTOTAL WHEN VARIANT OR CROSS SELL CHANGE
  useEffect(() => {
    setProductSubtotal(
      (selectedVariant?.price || 0) +
        formattedCrossSellVariants?.reduce(
          (acc, variant) => acc + variant.price?.amount,
          0
        )
    );
  }, [formattedCrossSellVariants, selectedVariant]);

  // SCROLL FUNCTION
  const scrollTo = selector => {
    if (isMobile) return null;
    const sectionEl = configuratorContainer?.current?.querySelector(selector);
    sectionEl?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  // SAVE FILTERS AND SELECTED PRODUCT ON URL PARAMS
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    // Save selection on URL Params
    Object.keys(selectedFilters).forEach(filter => {
      params.set(filter, kebabCase(selectedFilters[filter].text));
    });
    if (selectedProduct) {
      params.set("product", selectedProduct.slug!);
    } else if (!loadingURLProduct) {
      params.delete("product");
    }
    history.replaceState(null, "", "?" + params.toString());

    setShareUrl(`${location.origin}${location.pathname}?${params.toString()}`);
    return () => {};
  }, [selectedFilters, selectedProduct]);

  // SET PRODUCT AND VARIANT FROM URL PARAMS
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const productSlug = params.get("product");
    const selectedProduct: any = filteredProducts.find(
      product => product.slug === productSlug
    );
    if (selectedProduct) {
      const selectedVariant = selectedProduct?.variants.find(
        variant => variant.title === selectedFilters.productFabric.text
      );
      const selectedSeatingOption = productDropdownOptions.find(
        option =>
          selectedProduct.seats >= option.minValue &&
          selectedProduct.seats <= option.maxValue
      );
      setLoadingURLProduct(false);
      handleProductSelection({
        selectedProduct,
        selectedVariant,
        selectedSeatingOption,
      });
    }
  }, [filteredProducts]);

  const handleChat = () => {
    if (isFireworkAgentAvailable) {
      triggerFireworkWidget();
    } else {
      triggerChatBubble("Hello");
    }
  };

  // CustomizeSectionVisible
  const [customizeSectionVisible, setCustomizeSectionVisible] = useState(false);

  return (
    <MainLayout
      pageContext={pageContext}
      navHideEffect={false}
      showFooter={false}
    >
      <OkendoAssets />
      <div
        className={cx("flex flex-col items-start lg:flex-row", {
          "overflow-scroll": !isMobile,
        })}
        {...(!isMobile && {
          style: { height: `calc(100vh - ${height}px)` },
        })}
      >
        <div
          className={cx(
            "sticky top-0 z-20 h-full w-full lg:w-7/12 lg:self-center",
            { " bg-outer-light-gray": !isMobile }
          )}
        >
          <CarouselContainer
            mediaText={mediaText}
            modalMedia={modalMedia}
            selectedMedia={selectedMedia}
            selectedFiltersLength={Object.keys(selectedFilters).length}
            selectedProductExists={!!selectedProduct}
            mediaBlackOpacityPercent={mediaBlackOpacityPercent}
            mediaLink={mediaLink}
            mediaTranscriptUrl={mediaTranscriptUrl}
            id={galleryId}
          />
        </div>
        <div className="relative w-full lg:w-5/12">
          <div className="px-5 pb-16 pt-8 md:px-10">
            <div>
              {flags && (
                <div className="mb-14">
                  <FlagBadgeWrapper flags={flags} flagTypeSelected="all" />
                </div>
              )}

              <h1 className="mb-4 text-3xl text-primary-color lg:text-4xl">
                {title}
              </h1>
              {okendoReview && (
                <div className="mb-10 md:mb-14">
                  <Link to="/reviews">
                    <OkendoReviewsRating
                      collectionId={okendoReview.collectionId}
                    />
                  </Link>
                </div>
              )}
              <div
                className="mb-8 text-lg text-outer-grayscale-70 md:text-xl"
                dangerouslySetInnerHTML={{
                  __html: formatMarkdown(subtitle, false),
                }}
              />
              <div
                className="mb-14 text-lg text-primary-color md:text-xl"
                dangerouslySetInnerHTML={{
                  __html: formatMarkdown(description, false),
                }}
              />
            </div>
            <div>
              <ol
                className="relative list-inside list-decimal text-xl md:text-2xl"
                ref={configuratorContainer}
              >
                {sections.map((section, index) => {
                  const type = section.productFrames.length
                    ? "productFrame"
                    : "productFabric";

                  return (
                    <li
                      className="relative mb-10 md:mb-14"
                      key={`section-${index}`}
                      id={`section-${index}`}
                    >
                      <span>{section.primary}</span>
                      <SquareSelector
                        options={
                          section.productFrames.length
                            ? section.productFrames
                            : section.productFabrics
                        }
                        availableOptions={availableVariantOptions[type]}
                        selected={selectedFilters[type]}
                        setSelected={option =>
                          dispatchFilter({
                            type,
                            payload: option,
                          })
                        }
                        className="my-6"
                        showLabel
                        label={appContent.selectorLabel[type]}
                      />
                      {section.cta && (
                        <CTA
                          ctaData={section.cta}
                          className="text-sm text-outer-forest-green underline underline-offset-4"
                          inline
                        />
                      )}
                      {Object.keys(selectedFilters).length < 1 &&
                        index >= 1 && (
                          <div className="absolute inset-0 z-20 -mr-5 bg-outer-alpine-white opacity-70 md:-mr-10"></div>
                        )}
                    </li>
                  );
                })}
                {Object.keys(selectedFilters).length >= 2 && (
                  <>
                    <li className="mb-14" id="productsListContainer">
                      <span>
                        {productsSectionTitle ||
                          `Select or Build a Configuration`}
                      </span>
                      <div className="flex flex-col gap-y-7">
                        {productsSectionDescription && (
                          <div
                            className="mt-4 text-base text-secondary-color"
                            dangerouslySetInnerHTML={{
                              __html: formatMarkdown(
                                productsSectionDescription,
                                false
                              ),
                            }}
                          />
                        )}
                        <div
                          className={cx(
                            "mb-7 flex justify-center gap-x-8 rounded border border-solid border-outer-grayscale-30 bg-outer-light-gray px-4 py-2 md:p-4",
                            {
                              "mt-4": !!!productsSectionDescription,
                            }
                          )}
                        >
                          <button
                            className={cx("rounded px-6 py-2 text-lg", {
                              "bg-primary-color text-outer-alpine-white":
                                !customizeSectionVisible,
                            })}
                            onClick={() => setCustomizeSectionVisible(false)}
                          >
                            {productsTabTitle ?? "Popular"}
                          </button>
                          <button
                            className={cx("rounded px-6 py-2 text-lg", {
                              "bg-primary-color text-outer-alpine-white":
                                customizeSectionVisible,
                            })}
                            onClick={() => {
                              setCustomizeSectionVisible(true);
                              Analytics.track("Customize Option Click");
                            }}
                          >
                            {customizeTabTitle ?? "Customize"}
                          </button>
                        </div>
                      </div>

                      <Transition
                        show={customizeSectionVisible}
                        enter="transition-opacity duration-1000"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        unmount={false}
                      >
                        <div>
                          {customizeDescription && (
                            <div
                              className=" text-base text-secondary-color"
                              dangerouslySetInnerHTML={{
                                __html: formatMarkdown(
                                  customizeDescription,
                                  false
                                ),
                              }}
                            />
                          )}
                          {!!customizeHighlights.length && (
                            <ul
                              className="justify-left items-left flex flex-col gap-y-4 px-14 py-8"
                              role="list"
                            >
                              {customizeHighlights?.map((item, index) => (
                                <li
                                  key={`item-${index}`}
                                  className="flex items-start gap-4"
                                  role="listitem"
                                >
                                  {item.iconSelector &&
                                  item.iconSelector.media ? (
                                    <IconWrapper
                                      icon={item.iconSelector}
                                      className="flex-none"
                                    />
                                  ) : (
                                    <IconBullet className="mt-1 h-3 w-3" />
                                  )}
                                  <div className="flex-1  text-sm ">
                                    {item.primary && (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: formatMarkdown(
                                            item.primary,
                                            false
                                          ),
                                        }}
                                        className="inlineChilds"
                                      />
                                    )}
                                    {item.cta && (
                                      <CTA
                                        ctaData={item.cta}
                                        inline
                                        className="inline-flex text-left underline hover:underline"
                                      />
                                    )}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}
                          <Button
                            className="mb-8 !w-full"
                            onClick={() => {
                              const intercom: HTMLElement =
                                document.querySelector("#intercom-container") ||
                                document.querySelector(
                                  ".intercom-lightweight-app"
                                )!;
                              if (intercom) {
                                intercom.style.display = "unset";
                                triggerChatBubble("");
                              }
                              Analytics.track("Intercom Action Click");
                            }}
                            type="feature"
                            rightIcon="IconUpperRightArrow"
                          >
                            Talk to a Specialist
                          </Button>
                          {customizeMedia && (
                            <div className="ml-[calc(50%_-_50vw)] w-screen md:ml-auto md:w-full">
                              {customizeMedia.provider_metadata.resource_type ==
                              "video" ? (
                                <MemoizedVideoContainer
                                  videoSrc={customizeMedia.url}
                                  ratio={
                                    customizeMedia.width / customizeMedia.height
                                  }
                                  key={customizeMedia.url}
                                  className="!h-full"
                                />
                              ) : (
                                <ImageContainer
                                  src={customizeMedia.url}
                                  alt={customizeMedia.alternativeText}
                                  className="mb-8"
                                  sizes={`(max-width: 768px) ${HALF_VIEW_WIDTH}, (max-width: 1024px) ${ONE_THIRD_VIEW_WIDTH}, ${QUARTER_VIEW_WIDTH}`}
                                  manualTrigger={true}
                                  lazyLoad={false}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </Transition>

                      <Transition
                        show={!customizeSectionVisible}
                        enter="transition-opacity duration-1000"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <ProductDropdown
                          products={filteredProducts}
                          selectedFilter={selectedFilters}
                          selected={selectedProduct}
                          productDropdownOptions={productDropdownOptions}
                          onSelect={(
                            selectedProduct,
                            selectedVariant,
                            selectedSeatingOption
                          ) =>
                            handleProductSelection({
                              selectedProduct,
                              selectedVariant,
                              selectedSeatingOption,
                            })
                          }
                          selectedSeatingOption={
                            selectedSeatingOption! ||
                            productDropdownOptions.find(o => o.defaultOption)
                          }
                        />
                      </Transition>
                    </li>
                    {!customizeSectionVisible &&
                      !!crossSellProducts.length &&
                      selectedVariant && (
                        <li className="mb-14">
                          <span>Optional add-ons</span>
                          <MiniProductCardsBlock
                            parentVariantSlug={selectedVariant.slug!}
                            cards={crossSellProducts}
                            selectedMiniProductCardsVariantsObj={
                              selectedCrossSellVariants
                            }
                            setSelectedMiniProductCardsVariants={
                              setSelectedCrossSellVariants
                            }
                            className="mt-7"
                            parentComponent="crossSell"
                          />
                        </li>
                      )}
                  </>
                )}
              </ol>
              {!customizeSectionVisible && selectedVariant && (
                <>
                  {(selectedVariant.purchaseHighlights?.items.length > 0 ||
                    selectedVariant.shippingSLA) && (
                    <PurchaseHighlights
                      items={selectedVariant.purchaseHighlights?.items}
                      shippingSLA={selectedVariant.shippingSLA}
                      className="mb-14"
                    />
                  )}
                  <div className="mb-14 ">
                    <div className="flex-1">
                      {!selectedVariant.outOfStock ? (
                        <>
                          {selectedVariant.customCta?.enable ? (
                            <CustomCta
                              {...selectedVariant.customCta}
                              text={selectedVariant.customCta.text!}
                              fullWidth
                              className="mb-4"
                            />
                          ) : (
                            <Button
                              fullWidth
                              onClick={addToCart}
                              className="addToCartButton mb-4"
                              rightIcon="IconUpperRightArrow"
                              type="feature"
                            >
                              {selectedVariant.ctaAlias || "Add to Cart"}
                            </Button>
                          )}
                        </>
                      ) : (
                        <OutOfStockButton
                          outOfStockNotificationRequest={
                            outOfStockNotificationRequest
                          }
                          productSlug={selectedProduct.slug!}
                          variantSlug={selectedVariant.slug!}
                          className="mb-4"
                        />
                      )}
                    </div>
                    <Button
                      className="mb-4 !w-full"
                      onClick={() => {
                        const intercom: HTMLElement =
                          document.querySelector("#intercom-container") ||
                          document.querySelector(".intercom-lightweight-app")!;
                        if (intercom) {
                          intercom.style.display = "unset";
                          triggerChatBubble("");
                        }
                      }}
                    >
                      Review with a Designer
                    </Button>
                    <ShareButton
                      modalImage={selectedVariant.photos[0].url}
                      modalProductTitle={selectedProduct.title}
                      shareUrl={shareUrl}
                    />
                  </div>
                  {productAccordionMap.length > 0 && (
                    <Accordion items={productAccordionMap} icon="sign" />
                  )}
                </>
              )}
            </div>
          </div>
          {!customizeSectionVisible && selectedVariant && (
            <div
              className={cx(
                "sticky bottom-0 z-10 flex h-20 w-full items-center justify-evenly border-t border-solid border-outer-grayscale-20 bg-outer-alpine-white shadow-2xl shadow-outer-alpine-white transition-transform",
                {
                  "translate-y-full": !selectedVariant,
                }
              )}
              key={productSubtotal}
            >
              <div className="absolute -top-8 h-8 w-full bg-gradient-to-t from-outer-grayscale-20 opacity-50"></div>
              <div className="text-center text-2xl">
                {formatPrice(
                  productSubtotal / 100,
                  selectedProduct?.currency.code,
                  false,
                  false
                )}
                <p className="text-sm leading-none">Subtotal</p>
              </div>
              {!isMobile && (
                <>
                  <div className=" h-8 w-px bg-outer-grayscale-20" />
                  <div className="!anchor-underlined h-fit max-w-[150px] text-center text-sm lg:text-base">
                    <BuyNowPayLater
                      price={productSubtotal}
                      type={BNPLRenderType.MonthlyPriceWithLogo}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default ConfiguratorPage;

export const configuratorPageQuery = graphql`
  query CONFIGURATOR_PAGE_QUERY($configuratorPageId: ID!, $locale: String!) {
    alamedaapi {
      configuratorPage(id: $configuratorPageId) {
        title
        subtitle
        description
        mediaText
        mediaLink
        mediaBlackOpacityPercent
        mediaTranscriptUrl
        productsSectionTitle
        productsSectionDescription
        customizeDescription
        productsTabTitle
        customizeTabTitle
        customizeMedia {
          url
          alternativeText
          height
          width
          provider_metadata
        }
        customizeHighlights {
          primary
          iconSelector {
            media {
              name
              icon {
                url
                alternativeText
                height
                width
                provider_metadata
              }
            }
            width
            height
            color
          }
          cta {
            id
            location
            text
            event
            type
            rightIcon
            action
            textColor
            modal {
              primary
              secondary
              type
              media {
                url
                alternativeText
              }
              additionalTexts {
                text
              }
              ctas {
                text
                event
                location
                type
                rightIcon
                action
              }
            }
            mediaLink
            mediaTranscriptUrl
            pageModalSlug
            media {
              url
              width
              height
            }
            sharedGalleries {
              images {
                url
                alternativeText
                caption
                height
                width
                name
              }
            }
            productGallery {
              variants {
                defaultVariant
                photos {
                  url
                  alternativeText
                  caption
                  height
                  width
                  name
                }
                sharedGalleries {
                  images {
                    url
                    alternativeText
                    caption
                    height
                    width
                    name
                  }
                }
              }
            }
          }
        }
        media {
          url
          width
          height
          provider_metadata
        }
        modalMedia {
          url
          width
          height
          provider_metadata
        }
        okendoReview {
          collectionId
          productId
        }
        flags {
          ...AMAPI_FlagFields
        }
        sections {
          primary
          productFrames {
            text
            priority
            media {
              url
            }
            productMedia {
              label
              media {
                url
                width
                height
                provider_metadata
              }
              mobileMedia {
                url
                width
                height
                provider_metadata
              }
              productFabric {
                text
              }
            }
          }
          productFabrics {
            text
            priority
            media {
              url
            }
          }
          cta {
            ...AMAPI_CTAFields
          }
        }
        productDropdownOptions {
          text
          defaultOption
          minValue
          maxValue
        }
        products {
          enable
          remoteId
          id
          title
          slug
          seats
          currency {
            name
            code
            symbol
          }
          variants {
            remoteId
            enable
            slug
            title
            price
            id
            outOfStock
            ctaAlias
            photos {
              url
              alternativeText
              height
              width
              name
              provider_metadata
            }
            compareAtPrice
            salePriceText
            shippingSLA
            sharedGalleries {
              images {
                url
                alternativeText
                caption
                height
                width
                name
                provider_metadata
              }
            }
            customCta {
              text
              location
              event
              chatMessage
              enable
              type
              rightIcon
            }
            dimensionsImage {
              url
            }
            productShape {
              label
              text
            }
            productFabric {
              label
              text
            }
            productFrame {
              label
              text
            }
            configurationIcon {
              icon {
                url
              }
            }
            purchaseHighlights {
              items {
                primary
                iconSelector {
                  media {
                    name
                    icon {
                      url
                      alternativeText
                      height
                      width
                      provider_metadata
                    }
                  }
                  width
                  height
                  color
                }
                cta {
                  id
                  location
                  text
                  event
                  type
                  rightIcon
                  action
                  textColor
                  modal {
                    primary
                    secondary
                    type
                    media {
                      url
                      alternativeText
                    }
                    additionalTexts {
                      text
                    }
                    ctas {
                      text
                      event
                      location
                      type
                      rightIcon
                      action
                    }
                  }
                  mediaLink
                  mediaTranscriptUrl
                  pageModalSlug
                  media {
                    url
                    width
                    height
                  }
                }
              }
            }
          }
          crossSellProducts {
            id
            product {
              enable
              title
              slug
              variantType
              remoteId
              includeBNPLOptions
              currency {
                name
                code
                symbol
              }
              variants {
                id
                enable
                remoteId
                price
                title
                slug
                shippingSLA
                outOfStock
                compareAtPrice
                salePriceText
                salePriceTextModal {
                  primary
                  secondary
                  type
                  media {
                    url
                    alternativeText
                  }
                  additionalTexts {
                    text
                  }
                  ctas {
                    text
                    event
                    location
                    type
                    rightIcon
                    action
                  }
                }
                photos {
                  height
                  width
                  url
                  alternativeText
                  caption
                  provider_metadata
                }
                crossMerchMedia {
                  height
                  width
                  url
                }
                customCta {
                  enable
                }
                productShape {
                  label
                  text
                }
                productFabric {
                  label
                  text
                }
                productFrame {
                  label
                  text
                }
                productOption {
                  label
                  text
                }
                productOption2 {
                  label
                  text
                }
              }
            }
            productTitleAlias
            displayVariantSlug
            selectionType
            media {
              url
              alternativeText
            }
            selectorType
            useProductVariantsAsSelectors
            productShapes {
              label
              text
              priority
              media {
                url
              }
            }
            productFabrics {
              label
              text
              priority
              colorHex
              media {
                url
              }
            }
            productFrames {
              label
              text
              priority
              media {
                url
              }
            }
            productOptions {
              label
              text
              priority
              media {
                url
              }
            }
            productOption2s {
              label
              text
              priority
              media {
                url
              }
            }
          }
          okendoReview {
            collectionId
            productId
          }

          flags {
            ...AMAPI_FlagFields
          }
          sharedCrossSellProducts {
            clusters {
              id
              product {
                enable
                title
                slug
                variantType
                remoteId
                includeBNPLOptions
                currency {
                  name
                  code
                  symbol
                }
                variants {
                  id
                  enable
                  remoteId
                  price
                  title
                  slug
                  shippingSLA
                  outOfStock
                  compareAtPrice
                  salePriceText
                  salePriceTextModal {
                    primary
                    secondary
                    type
                    media {
                      url
                      alternativeText
                    }
                    additionalTexts {
                      text
                    }
                    ctas {
                      text
                      event
                      location
                      type
                      rightIcon
                      action
                    }
                  }
                  photos {
                    height
                    width
                    url
                    alternativeText
                    caption
                    provider_metadata
                  }
                  customCta {
                    enable
                  }
                  productShape {
                    label
                    text
                  }
                  productFabric {
                    label
                    text
                  }
                  productFrame {
                    label
                    text
                  }
                  productOption {
                    label
                    text
                  }
                  productOption2 {
                    label
                    text
                  }
                }
              }
              productTitleAlias
              displayVariantSlug
              selectionType
              media {
                url
                alternativeText
              }
              selectorType
              useProductVariantsAsSelectors
              productShapes {
                label
                text
                priority
                media {
                  url
                }
              }
              productFabrics {
                label
                text
                priority
                colorHex
                media {
                  url
                }
              }
              productFrames {
                label
                text
                priority
                media {
                  url
                }
              }
              productOptions {
                label
                text
                priority
                media {
                  url
                }
              }
              productOption2s {
                label
                text
                priority
                media {
                  url
                }
              }
            }
          }
          stackComponents {
            __typename
            ... on AMAPI_ComponentStackProductAccordion {
              __typename
              id
              items {
                title
                content
                enable
                productAccordion {
                  title
                  content
                  enable
                }
              }
            }
          }
        }
      }
      outOfStockNotificationRequest(locale: $locale) {
        locale
        primary
        klaviyoList
        form_confirmation {
          primary
          body
        }
      }
    }
  }
`;
